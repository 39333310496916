import React, { Component } from 'react';
import config from '../Config';
import InstagramFeed from './InstaFeed';
import ContactAction from './ContactAction';

class Footer extends Component {
render() {
    const currentYear = new Date().getFullYear();
    const location = window.location.pathname

    return (
        <div className='b-dark'>
            
            {location != '/contact' && <ContactAction></ContactAction>}

            <footer className='p-tb-2'>
                <div className='m-c content-wrapper'>
                    <ul className='c-60 m-c t-a-c'>
                        
                        <li className='c-50 d-i-b v-a-m sm-c-100'>
                            <h6 className='sans-serif d-b c-light'>
                                <span className='m-b-1 sans-serif d-b'>© {currentYear} {config.name}</span>
                                <a href={config.instagram_url}  target="_blank" className='c-light m-b-1 sans-serif d-b'>
                                    {config.instagram_account_name}
                                </a>
                            </h6>
                        </li>
                        
                        <li className='c-50 d-i-b v-a-m sm-c-100'>
                            <h6 className='sans-serif d-b'>
                                <a href={`mailto:${config.email}`} className='c-light m-b-1 sans-serif d-b'>{config.email}</a>
                                <a href={`tel:${config.phone_plain}`} className='c-light m-b-1 sans-serif d-b '>{config.phone}</a>
                            </h6>
                        </li>
                    </ul>
                </div>    
            </footer>

        </div>
        );
    }
}

export default Footer;
