import Home from '../pages/Home';
import Philosophy from '../pages/Philosophy';
import Contact from '../pages/Contact';
import Services from '../pages/Services';
import Items from '../pages/Items';
import Details from '../pages/Details';

const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
    public: true,
  },
  {
    path: '/filosofia',
    label: 'Filosofia',
    component: Philosophy,
    exact: false,
    public: true
  },
  {
    path: '/progetti',
    label: 'Progetti',
    component: Items,
    exact: false,
    public: true
  },
  {
    path: '/servizi',
    label: 'Servizi',
    component: Services,
    exact: false,
    public: true
  },
  {
    path: '/design',
    label: 'Design',
    component: Items,
    exact: false,
    public: false
  },
  {
    path: '/details/:type/:id',
    label: null,
    component: Details,
    exact: false,
    public: true
  },
  {
    path: '/contact',
    label: 'Contatti',
    component: Contact,
    exact: false,
    public: true
  }
];

export default routes;
