import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import RoutesConfig from '../utils/RoutesConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showMenu: false
        };
    }
    
    toggleMenu = () => {
        this.setState({ showMenu: !this.state.showMenu });
    };
    
    render() {

        return (
            <div>
                <div onClick={this.toggleMenu} className='d-n sm-d-b t-a-r'>
                    <FontAwesomeIcon className='icon-menu' icon={faBars} />
                </div>

                <ul className={`t-a-r d-b sm-p-a sm-c-100 sm-b-light sm-l-0 main-menu ${this.state.showMenu ? 'show' : ''}`}>
                    <ul className='sm-p-tb-3'>
                        {RoutesConfig.map((route, index) => (
                            route.label && route.public && (
                                <li className='d-i-b v-a-m nav-item sm-d-b sm-c-100 sm-t-a-c' key={index}>
                                    <Link onClick={this.toggleMenu} to={route.path} exact className='d-b nav-link sans-serif c-light sm-c-dark' activeClassName='active-link'>
                                        {route.label}
                                    </Link>
                                </li>
                            )
                        ))}
                    </ul>
                </ul>
            
            </div>
        );
    }
}

export default Navbar;
