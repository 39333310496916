import React, { Component, useState, useEffect } from 'react';
import config from '../Config';
import philosophy from '../assets/images/philosophy.png';

class Philosophy extends Component {  
    
    componentDidMount() {
        document.title = `${config.name} ・ Filosofia`;
    }

    render() {

        return (
            <div className='b-light'>
                <div className='content-wrapper m-c p-tb-15 sm-p-rl-2'>
                    <ul>
                        <li className='c-40 d-i-b v-a-t p-s t-150 animated-item fade-in-slide-up sm-d-n d-b'>
                            <img className='c-80' src={philosophy} />
                        </li>

                        <li className='c-60 d-i-b v-a-m sm-c-100'>
                            <div className='animated-item fade-in-slide-up delay-1'>
                                <h1 className='madegra'>Filosofia</h1>
                                <h5 className='uppercase sans-serif'>Il tuo spazio si crea pian piano.<br/>intorno a te</h5>
                                <img className='c-80 d-n sm-d-b sm-c-60 m-c sm-m-t-2 sm-m-b-2' src={philosophy} />
                            </div>
                            <p class='m-t-2 animated-item fade-in-slide-up delay-2' dangerouslySetInnerHTML={{ __html: config.philosophy }} />
                        </li>
                    </ul>
                </div>
            </div>
        );
  }
}

export default Philosophy
