import React, { Component } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

class ImagePopup extends Component {
    render() {
        const { imageUrl, onClose } = this.props;

        return (
            <Modal isOpen={!!imageUrl} onRequestClose={onClose} contentLabel="Immagine Popup" className="custom-modal d-t-c v-a-m" overlayClassName="custom-modal-overaly p-f c-100 h-100 d-t t-a-c">
                <button onClick={onClose} className='custom-modal-close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                <img src={imageUrl} className='custom-modal-popup-image' />
            </Modal>
        );
    }
}

export default ImagePopup;
