import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RoutesConfig from './utils/RoutesConfig';
import './assets/css/main.css';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import config from './Config.js';

class App extends Component {

	componentDidMount() {
        document.title = `${config.name}`;
    }

	render() {

		return (
		  	<Router>
				<div>
			  		<Switch>

						<div>
							<Header />

							<div>
								{ RoutesConfig.map((route, index) => (
									<Route 
										key={index} 
										path={route.path} 
										exact={route.exact} 
										component={route.component}
									/>
								))}
							</div>

							<Footer />
						</div>
					
					</Switch>
				</div>
		  	</Router>
		);
	}
}

export default App;
