import React, { Component } from 'react';
import config from '../Config';

class ContactAction extends Component {
    render() {

        return (
            <div className='content-wrapper m-c'>
                <div className='p-tb-10 animated-item fade-in-slide-up delay-2'>
                    <h4 className='c-light'>Dai vita ai tuoi desideri di design.</h4>
                    <p className='m-t-2 c-light'><span className='c-secondary p-lr-2 sm-d-n'>〉</span>Contattami per dare il via a un viaggio unico nella creazione del tuo ambiente.</p>
                    <h6 className='m-t-5 d-i-b'><a href={`mailto:${config.email}`} className='c-light button sans-serif uppercase'>Scrivimi via E-Mail</a></h6>
                </div>
            </div>
        );
    }
}

export default ContactAction;
