import React, { Component } from 'react';
import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.svg';

class Header extends Component {
    
    render() {
        return (
        <header className="b-dark header z-5 c-100">
            <ul className='content-wrapper m-c'>
                <li className='d-i-b c-30 v-a-m sm-c-80 t-a-l'>
                    <Link className='d-b' to='/'>
                        <img className='inverted logo' src={logo} />
                    </Link>
                </li>

                <li className='d-i-b c-70 v-a-m sm-c-20'>
                    <Navbar/>
                </li>
            </ul>
        </header>
        );
    }
}

export default Header;
