import React, { Component } from 'react';
import config from '../Config';
import services from '../assets/images/services.png';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services: [],
            loading: true,
            error: null,
        };
    }

    async componentDidMount() {
        try {
            const type = "servizi"
            const response = await fetch(`/data/${type}.json`);

            document.title = `${config.name} ・ Servizi`;

            this.setState({type: type})

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            this.setState({
                items: data.items,
                loading: false,
            });
        
        } catch (error) {
            this.setState({
                error: error.message,
                loading: false,
            });
        }
    }

    render() {
        const { items, loading, error, type } = this.state;

        if (loading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <div className='b-light'>
                <div className='content-wrapper m-c p-tb-15 sm-p-rl-2'>
                    <ul>
                        <li className='c-40 d-i-b v-a-t p-s t-150 animated-item fade-in-slide-up sm-d-n d-b'>
                            <img className='c-80' src={services} />
                        </li>

                        <li className='c-60 d-i-b v-a-m sm-c-100'>
                            <div className='animated-item fade-in-slide-up delay-1'>
                                <h1 className='madegra animated-item fade-in-slide-up'>  Servizi</h1>
                                <h5 className='sans-serif m-t-2 animated-item fade-in-slide-up delay-1'>Il tuo spazio, la tua visione, la nostra passione.</h5>
                                <img className='c-80 d-n sm-d-b sm-c-60 m-c sm-m-t-2 sm-m-b-2' src={services} />
                            </div>
                            <p class='m-t-2 animated-item fade-in-slide-up delay-2' dangerouslySetInnerHTML={{ __html: config.services }} />
                        </li>
                    </ul>
                    
                    <ul className='m-t-5 col-3 sm-col-1'>
                        {items.map((item) => (
                            <li key={item.id} className='m-b-3 sm-d-b sm-c-100 animated-item fade-in-slide-up'>
                                <h4>{item.name}</h4>
                                <ul className='m-t-2 sm-c-100'>
                                    {item.services.map((service) => (	
                                        <li className='m-b-1'>
                                            <ul>
                                                <li className='c-10 d-i-b v-a-t t-a-c sm-d-n'>
                                                    <h6 className='p-t-5px c-secondary sans-serif'>〉</h6>
                                                </li>
                                                <li className='c-90 d-i-b v-a-t'>
                                                    {service}
                                                </li>
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
            
                </div>
            </div>
        );
    }
}

export default Services;
