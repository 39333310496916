import React, { Component, useState, useEffect } from 'react';
import RandomProjects from '../components/RandomProjects';
import config from '../Config';
import CoverImage from '../components/CoverImage';

class Home extends Component {  
    
	componentDidMount() {
        document.title = `${config.name}`;
    }
    
    render() {
        
        return (
            <div className='b-light'>
                <div className='relative c-100 vh-85 b-dark p-r d-t'>
                    <CoverImage url='images/home.jpg' title={config.title} subtitle={config.subtitle} />
                </div>

                <div className='content-wrapper m-c p-tb-5'>
                    
                    <div  className='p-tb-5'>
                        <h3 className='madegra animated-item fade-in-slide-up'>Vivi l'esperienza del design d'interni su misura</h3>
                        <p className='m-t-2  animated-item fade-in-slide-up delay-1'>
                            Attraverso la mia passione per l'interior design, ti guiderò nella creazione di uno spazio che rispecchia il tuo stile unico, trasformando ogni ambiente in una testimonianza della tua personalità e del tuo gusto.
                        </p>
                    </div>

                    <RandomProjects></RandomProjects>
                </div>
            </div>
        );
    }
}

export default Home
