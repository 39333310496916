import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import config from '../Config';

class Items extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: true,
            error: null,
        };
    }

    async componentDidMount() {
        try {
            const type = window.location.pathname.split('/')[1];
            const response = await fetch(`/data/${type}.json`);
            
            document.title = `${config.name} ・ ${type.replace(/^\w/, (c) => c.toUpperCase())}`;

            this.setState({type: type})


            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            this.setState({
                items: data.items,
                loading: false,
            });
        
        } catch (error) {
            this.setState({
                error: error.message,
                loading: false,
            });
        }

    }

    render() {
        const { items, loading, error, type } = this.state;

        if (loading) {
            return <div>Loading...</div>;
        }

        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <div className='b-light'>
                <div className='content-wrapper m-c p-tb-15'>
                    <h1 className='madegra animated-item fade-in-slide-up'>  
                        {type === 'progetti' ? (
                            "Un progetto, un pezzo d'arte."
                        ) : (
                            "Lasciati Ispirare: Design Iconici"
                        )}        
                    </h1>
                    <h5 className='sans-serif m-t-2 animated-item fade-in-slide-up delay-1'>
                        {type === 'projects' ? (
                            "Scopri i miei lavori e lasciati ispirare."
                        ) : (
                            "Esplora la bellezza di forme concepite per impressionare e durare nel tempo."
                        )}
                    </h5>
                    
                    <ul className='m-t-5'>
                        {items.map((item) => (
                            <li key={item.id} className='m-b-3 sm-d-b sm-c-100 animated-item fade-in-slide-up'>
                                <Link to={`/details/${type}/${item.id}`}>
                                    <ul className='p-r'>
                                        <li className='c-50 d-i-b v-a-m sm-c-100 p-r-2 sm-p-0'>
                                            <div className='c-100 preview-project' style={{backgroundImage: `url("/images/${type}/${item.id}/preview.jpg")`}}></div>
                                        </li>
                                        <li className='c-50 d-i-b v-a-m sm-c-100 p-r-2 sm-p-0'>
                                            <h6 className='sans-serif uppercase c-secondary'>{item.year} - {item.location}</h6>
                                            <h4>{item.title}</h4>
                                        </li>
                                    </ul>
                                </Link>
                            </li>
                        ))}
                    </ul>
            
                </div>
            </div>
        );
    }
}

export default Items;
