import React, { Component } from 'react';
import config from '../Config';

class Contact extends Component {

	componentDidMount() {
        document.title = `${config.name} ・ Contatti`;
    }

    render() {
        return (
            <div className='b-light'>
                <div className='content-wrapper m-c p-tb-15'>
                    <div className='c-100'>
                        <h1 className='madegra animated-item fade-in-slide-up'>{config.name}</h1>
                        <h5 className='sans-serif animated-item fade-in-slide-up delay-1'>
                            Dai vita alla tua idea: scrivimi per iniziare.
                        </h5>
                        <ul className='m-t-5 animated-item fade-in-slide-up delay-2'>
                            <li className='c-33 d-i-b v-a-m sm-c-100 sm-m-b-2'>
                                <h6 className='sans-serif uppercase '>
                                    E-Mail
                                </h6>
                                <p>
                                    <a href={`mailto:${config.email}`}>{config.email}</a>
                                </p>
                            </li>
                            <li className='c-33 d-i-b v-a-m sm-c-100 sm-m-b-2'>
                                <h6 className='sans-serif uppercase'>
                                    Telefono
                                </h6>
                                <p><a href={`tel:${config.phone_plain}`}>{config.phone}</a></p>
                            </li>
                            <li className='c-33 d-i-b v-a-m sm-c-100'>
                            <h6 className='sans-serif uppercase'>
                                Instagram
                            </h6>
                            <p>
                            <a href={config.instagram_url} target='_blank'>{config.instagram_account_name}</a>
                            </p>
                            </li>
                        </ul>
                    </div>
                </div>  
            </div>      
        );
    }
}

export default Contact
