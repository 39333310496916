const config = {
    name: 'La Petreccia',
    title: 'Habitat Nest <span class="middle-font-size">Concept</span>',
    subtitle: 'Crea un rifugio nel tuo spazio',
    phone: '+41 78 792 03 86',
    phone_plain: '0041787920386',
    email: 'studio@lapetreccia.com',
    instagram_url: 'https://www.instagram.com/lapetreccia_studio/',
    instagram_account_name: '@lapetreccia_studio',
    bio: "Vivi l'esperienza del design d'interni su misura. Attraverso la mia passione per l'interior design, ti guiderò nella creazione di uno spazio che rispecchia il tuo stile unico, trasformando ogni ambiente in una testimonianza della tua personalità e del tuo gusto",
    philosophy: "Con lo scandire delle passioni, delle tue giornate o delle tue pause dal mondo. Il tuo nido prende forma dai tuoi passi mentre ascolti il tuo disco preferito, muovendoti dal giradischi fino alla vasca da bagno da riempire, mentre accendi una candela. Prima di essere fatto di pareti, il tuo nido si compone dentro di te, fatto dei tuoi bisogni che ti accompagnano per tutto il giorno per aspettarti a casa.</br></br>Nella mia filosofia di progettazione, ad ognuno la sua eleganza personale. Progettare i vostri spazi è un viaggio, un'esplorazione delle vostre passioni e di tutte le sfumature e i colori che appartengono alla vostra anima. Chi vuoi essere nel tuo nido? Come lo immagini ad accoglierti dopo una lunga giornata? Ogni elemento, ogni dettaglio è scelto con cura per donare un equilibrio e armonia tra te e il tuo habitat.</br></br>La mia progettazione mira a infondere calore in ogni angolo poiché questo possa accoglierti e rigenerati in esso. L'ecletticità di ogni progetto porta con sé il sapore e le atmosfere soft e materiche dai tratti mediterranei, sapendosi impreziosire di stucchi, ma con l'audacia e il carattere di un'anima folk. La tua essenza e le tue passioni sono le uniche cose di cui hai bisogno quando torni a casa, e il tuo nido le riflette e custodisce.",
    services: "Entra nell'universo incantato dei tuoi sogni. Qui, la tua immaginazione prende forma e colore mentre trasformiamo le tue visioni in spazi tangibili. Con una combinazione unica di creatività e competenza, guidiamo il tuo progetto dalla concezione alla realizzazione, dando vita alle tue idee più audaci con passione e dedizione. Preparati a vivere l'esperienza di vedere i tuoi sogni trasformarsi in realtà, con ogni dettaglio curato amorevolmente per rendere il tuo spazio un vero rifugio di ispirazione e bellezza."
  };
  
  export default config;