import React, { Component } from 'react';
import ImagePopup from '../components/ImagePopup';
import CoverImage from '../components/CoverImage';
import config from '../Config';

class Details extends Component {
    constructor(props) {
        super(props);

        this.state = {
            item: null,
            selectedImageUrl: null,
        };
    }
  
    openImagePopup = (imageUrl) => {
        this.setState({ selectedImageUrl: imageUrl });
    };

    closeImagePopup = () => {
        this.setState({ selectedImageUrl: null });
    };

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        try {
            const { type, id } = this.props.match.params;

            const response = await fetch(`/data/${type}.json`);
            const jsonData = await response.json();

            const itemId = parseInt(id, 10);

            const foundItem = jsonData.items.find(item => item.id === itemId);

            this.setState({ item: foundItem, type: type, id: id });

            document.title = `${config.name} ・ ${foundItem.title}`;

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    render() {
        const { item, type, id, selectedImageUrl } = this.state;
        const previewUrl = `/images/${type}/${id}/preview.jpg`;
        const overlay = true

        return (
            <div className='b-light'>
                
                {item ? ( 
                    <div>
                        <div className='relative c-100 vh-70 b-dark p-r d-t'>
                            <CoverImage url={previewUrl} title={item.title} subtitle={`${item.year} | ${item.location}`} overlay={overlay} />
                        </div>

                        <div className='d-b p-tb-7 content-wrapper m-c preview animated-item fade-in-slide-up delay-2'>
                            <p className='col-2 sm-col-1'>{item.description}</p>    

                            <div className="wonderful-grid m-t-5">
                                {Array.from({ length: item.images }, (_, index) => (
                                    
                                    <div key={index} className="grid-item" onClick={() => this.openImagePopup(`/images/${type}/${item.id}/${index + 1}.jpg`)}>
                                        <img src={`/images/${type}/${item.id}/${index + 1}.jpg`} alt={`Image ${index + 1}`} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        
                        <ImagePopup imageUrl={selectedImageUrl} onClose={this.closeImagePopup} />

                    </div>
                ) : (
                    
                    <div className='content-wrapper m-c t-a-c vh-90 d-t'>
                        <div className='d-t-c v-a-m'>
                            <h2 className='madegra c-secondary'>Non trovato</h2>
                        </div>
                    </div>
                
                )}
            
            </div>
        );
    }
}

export default Details;
