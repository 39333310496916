import React from 'react';

const CoverImage = ({ url, title, subtitle, overlay }) => {
    const divStyle = { 
        backgroundSize: `cover`,
        backgroundImage: `url(${url})`,
        backgroundAttachment: `fixed`
    };

    return (
        <div  className='cover-image c-100 p-a h-100 d-t greyscale' style={divStyle}>
            { overlay && <div className='overlay c-100 h-100 p-a t-0 l-0 z-1'></div>}
            <ul className='d-t-c c-100 h-100 v-a-m z-2 p-r sm-b-d-soft'>
                <li className='c-50 d-i-b v-a-m sm-d-n'></li>
                <li className='c-50 d-i-b v-a-m animated-container sm-c-100 sm-p-lr-1 sm-m-t-20'>
                    { title && <h1 className='madegra animated-item fade-in-slide-up c-light'>
                        <span dangerouslySetInnerHTML={{ __html: title }} />
                    </h1> } 

                    { subtitle && <h5 className='sans-serif uppercase animated-item fade-in-slide-up c-light delay-1 large-letter-spacing'>
                        <span dangerouslySetInnerHTML={{ __html: subtitle }} />
                    </h5> }
                </li>
            </ul>
        </div>
    );
};

export default CoverImage;