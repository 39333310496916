import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const RandomProjects = () => {
const [selectedItems, setSelectedItems] = useState([]);

useEffect(() => {
    const fetchData = async () => {
    try {
        const response = await fetch('/data/progetti.json');
        const data = await response.json();
        const shuffledData = [...data.items].sort(() => 0.5 - Math.random());
        const selected = shuffledData.slice(0, 3);
        setSelectedItems(selected);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
    };

    fetchData();
}, []);

return (
    <div className='m-t-5 animated-item fade-in-slide-up delay-2'>
        <h6 className='sans-serif uppercase'>Alcuni progetti...</h6>
        <ul>
            {selectedItems.map((item) => (
                <li key={item.id} className='p-tb-3 p-r'>
                    <Link to={`/details/progetti/${item.id}`}>
                        <ul className='c-50 random-preview-image-container sm-c-100'>
                            <div className='random-preview-image sm-d-b' style={{backgroundImage: `url("/images/progetti/${item.id}/preview.jpg")`}}></div>
                            <ul className='p-r'>
                                <li className='c-15 d-i-b v-a-m sm-c-100'>
                                    <h6 className='sans-serif uppercase c-secondary'>{item.year}</h6>
                                </li>
                                <li className='c-85 d-i-b v-a-m sm-c-100'>
                                    <h4>{item.title}</h4>
                                </li>
                            </ul>
                        </ul>
                    </Link>
                </li>
            ))}
        </ul>
    </div>
);
};

export default RandomProjects;
